<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" class="n-modal-container" :label-width="labelWidth" :model="model" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" label="id" disabled />
        <n-field-id v-model="model.sourceId" label="sourceId" disabled />
      </n-row-col2>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="!isCreate || disabled" />
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.sourceAccountId" field-name="sourceAccountId" label="sourceAccountId" :error="error.sourceAccountId" :disabled="isEdit || disabled" />
        <n-field-id v-model="model.sourceCategoryId" field-name="sourceCategoryId" label="sourceCategoryId" :error="error.sourceCategoryId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.type" :error="error.type" enum-name="TransactionType" label="type" :disabled="disabled" />
        <n-field-enum v-model="model.status" :error="error.status" enum-name="CustomerBudgetStatus" label="status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.frequency" :error="error.frequency" enum-name="CustomerBudgetFrequency" label="frequency" disabled />
        <n-field-boolean v-model="model.repeatable" field-name="repeatable" label="repeatable" :error="error.repeatable" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-decimal v-model="model.amount" label="amount" :disabled="disabled" />
        <n-field-selection v-model="model.currency" :error="error.currency" selection-name="market.currency" label="currency" :disabled="!isCreate || disabled"  />
      </n-row-col2>
      <n-row-col2>
        <!-- startDate -->
        <n-field-date v-model="model.startDate" label="startDate" disabled />
        <!-- finishDate -->
        <n-field-date v-model="model.finishDate" label="finishDate" disabled />
      </n-row-col2>
      <n-row-col2>
        <!-- startTime -->
        <n-field-datetime v-model="model.startTime" label="startTime" disabled />
        <!-- finishTime -->
        <n-field-datetime v-model="model.finishTime" label="finishTime" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, update, add } from '@/api/customer/customer-customer-budget';

export default NModal.extend({
  name: 'ModalCustomerBudget',
  components: { NModal },

  methods: {
    doValidate() {
      return validate(this.model);
    },

    doSubmit() {
      return this.isCreate ? add(this.model) : update(this.model);
    },
  },
});
</script>
