import {$fetch, $post, $put} from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/budgets`, params);
}

export const CUSTOMER_BUDGET_CSV_URL = `customer/budgets/csv`;

export function validate(model) {
  return $post(`customer/budget/validate`, model);
}

export function add(model) {
  return $put(`customer/budget`, model);
}

export function update(model) {
  return $post(`customer/budget/${model.id}`, model);
}
