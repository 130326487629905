<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <nb-download :url="CUSTOMER_BUDGET_CSV_URL" :params="condition" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <ns-currency v-model="condition.currency" all-option />
      <n-field-enum v-model="condition.status" enum-name="CustomerBudgetStatus" label="status" all-option />
      <n-field-enum v-model="condition.type" enum-name="TransactionType" label="type" all-option />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-12">{{ $t('label.company') }}</th>
            <th class="col-weight-12">{{ $t('label.customerId') }}</th>
            <th class="col-weight-12">{{ $t('label.frequency') }}</th>
            <th class="col-weight-18">{{ $t('label.repeatable') }}</th>
            <th class="col-weight-12">{{ $t('label.type') }}</th>
            <th class="col-weight-12 n-text-right">{{ $t('label.amount') }}</th>
            <th class="col-weight-12">{{ $t('label.status') }}</th>
            <th class="n-table-timestamp">{{ $t('label.startTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.profile" :value="row.customerId" />
            </td>
            <td v-enums:CustomerBudgetFrequency="row.frequency"></td>
            <td><n-icon-boolean :value="row.repeatable" /></td>
            <td v-enums:TransactionType="row.type"></td>
            <td class="n-text-right">
              <n-number :value="row.amount" :scale="row.currencyScale" :currency="row.currency" />
            </td>
            <td v-enums:CustomerBudgetStatus="row.status"></td>
            <td>{{ row.startTime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import { CURRENCY_SCALE_NAME } from '@/constant/enums';
import ModalCustomerBudget from './ModalCustomerBudget';
import { CUSTOMER_BUDGET_CSV_URL, fetch } from '@/api/customer/customer-customer-budget';
import { Parser, setCurrencyScale } from '@/helpers/operators';

export default CustomerView.extend({
  name: 'CustomerCustomerBudget',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        currency: 0,
        customerId: null,
        type: 0,
        status: 0,
        from: this.oneWeekAgo,
        to: this.eod,
      },
      CUSTOMER_BUDGET_CSV_URL,
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },

    parse(rows) {
      this.records = Parser(rows.records, [setCurrencyScale(CURRENCY_SCALE_NAME.CURRENCY)]);
    },

    openModal(model = {}) {
      this.createModal(ModalCustomerBudget, { model, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 1080px;

  th.rw-action {
    width: 4rem;
  }

  th.ro-action {
    width: 4.5rem;
  }
}
</style>
